import * as React from 'react';
import type { SVGProps } from 'react';

function SvgKotaSymbolAndWordmarkMonochromeLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 304 75' {...props}>
      <path
        d='M222.3 46.2C222.3 59.6 212.1 68.1 199.8 68.1C187.4 68.1 177.2 59.7 177.2 46.2C177.2 32.7 187.4 24.2 199.8 24.2C212.1 24.2 222.3 32.7 222.3 46.2ZM211.3 46.2C211.3 37.9 207.6 34.1 199.8 34.1C191.9 34.1 188.2 38 188.2 46.2C188.2 54.3 191.9 58.2 199.8 58.2C207.6 58.2 211.3 54.3 211.3 46.2ZM243.6 34.7C242.6 34.7 242.5 34.8 242.5 35.8V56.5C242.5 57.5 242.6 57.6 243.6 57.6H251.5C253.5 57.6 253.7 57.8 253.7 59.8V65.1C253.7 67.1 253.5 67.3 251.5 67.3H244.6C236.8 67.3 231.5 62.1 231.5 54.2V35.8C231.5 34.8 231.4 34.7 230.4 34.7H225.6C223.6 34.7 223.4 34.5 223.4 32.5V27.2C223.4 25.2 223.6 25 225.6 25H230.4C231.4 25 231.5 24.9 231.5 23.9V14.6C231.5 12.6 231.7 12.4 233.7 12.4H240.3C242.3 12.4 242.5 12.6 242.5 14.6V23.9C242.5 24.9 242.6 25 243.6 25H252.6C254.6 25 254.8 25.2 254.8 27.2V32.5C254.8 34.5 254.6 34.7 252.6 34.7H243.6ZM301 25.1C303 25.1 303.2 25.3 303.2 27.3V65.1C303.2 67.1 303 67.3 301 67.3H294.4C292.4 67.3 292.2 67.1 292.2 65.1V60.2C292.2 59.4 291.7 59.3 291.3 60C288.5 65.2 283.8 68.1 277.6 68.1C265.6 68.1 258.4 59.3 258.4 46.2C258.4 32.9 265.3 24.2 277.1 24.2C283.6 24.2 288.5 27.2 291.4 32.6C291.8 33.3 292.3 33.2 292.3 32.4V27.2C292.3 25.2 292.5 25 294.5 25H301V25.1ZM292.6 46.2C292.6 37.9 288.9 34.1 280.9 34.1C273.2 34.1 269.5 38 269.5 46.2C269.5 54.3 273.2 58.2 280.9 58.2C288.9 58.2 292.6 54.3 292.6 46.2ZM175.7 67.2C177.3 67.2 177.6 66.6 176.6 65.4L154.8 38.2C153.3 36.4 153.3 36.3 154.8 34.5L176.4 8.90002C177.4 7.70002 177.2 7.00002 175.5 7.00002H165.8C163.4 7.00002 163.7 6.90004 162.1 8.70004L144 30.4C143.1 31.5 143.2 31.5 141.8 31.5H137.6C136.6 31.5 136.5 31.4 136.5 30.4V9.20004C136.5 7.20004 136.3 7.00002 134.3 7.00002H126.5C124.5 7.00002 124.3 7.20004 124.3 9.20004V65.1C124.3 67.1 124.5 67.3 126.5 67.3H134.4C136.4 67.3 136.6 67.1 136.6 65.1L136.7 43.5C136.7 42.5 136.8 42.4 137.8 42.4H142.1C143.6 42.4 143.4 42.3 144.4 43.5L162.1 65.5C163.6 67.4 163.4 67.3 165.8 67.3H175.7V67.2Z'
        fill='currentColor'
      />
      <path
        d='M97.2 5.59997C97.1 4.69997 97 3.69995 96.8 2.79995C96.4 0.0999477 95.7 -0.300034 93.3 1.09997L45.7 28.6L49.8 17C51.6 11.8 51.5 11.4 46.5 8.99996C46.4 8.89996 46.3 8.89995 46.2 8.79995C43.3 7.39995 42.8 7.59997 41.7 10.7L37 23.9L31.3 11.4C28.7 5.69995 28.2 5.49997 22.3 7.59997C19.8 8.49997 19.6 8.89995 20.7 11.2999L27 25.2L13 19.6C7.8 17.5 7.4 17.6 4.8 22.6C4.70001 22.9 4.5 23.1 4.4 23.4C3.3 25.7 3.4 26.1 5.8 27.1L19.2 32.5L5.8 36.4C0.400005 38 0.200008 38.2 1.10001 43.7999C1.10001 44 1.2 44.2 1.2 44.4C1.7 47.3 2.1 47.5999 5 46.7999L20.4 42.4L11.3 56.2C8.39999 60.6 8.39999 60.9 12.3 64.5C12.6 64.8 12.9 65.1 13.3 65.4C15.4 67.2 15.8 67.1 17.3 64.9L26.8 50.4L28.6 68.5C29.1 73.8 29.3 74 34.6 74.4C35.1 74.4 35.7 74.5 36.3 74.5C38.7 74.6 39 74.3 38.7 71.9L36.9 53.7999L49.4 68.7C52.4 72.3 52.4 72.3 56.7 70.6C57.5 70.3 58.1 70 59 69.6C61.4 68.5 61.5 68 59.8 66L46.4 50L72 58.2999C75.8 59.5 75.5 59.6 78.4 56.9C79.4 56 80.1 55.3 80.9 54.5C82.9 52.4 82.7 51.6 79.9 50.7L57 43.2999L88.1 38.9C92 38.3 91.8 38.5 93.3 34.7999C93.8 33.5999 94.1 32.6 94.4 31.5C95.2 28.7 94.7 28.1 91.8 28.5L57 33.4L93.8 12.1C97.7 9.79997 97.7 9.89997 97.2 5.59997ZM36.9 42.1C34.7 43.3 32 42.6 30.8 40.5C29.6 38.3 30.3 35.6 32.4 34.4C34.5 33.2 37.3 33.9 38.5 36C39.8 38.1 39.1 40.9 36.9 42.1Z'
        fill='currentColor'
      />
    </svg>
  );
}
export default SvgKotaSymbolAndWordmarkMonochromeLogo;
